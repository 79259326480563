 <template>
    <modal
        height="auto"
        :show="true"
        scrollable
        size="middle"
        @hide="$emit('sendInformationHide')"
    >
        <div slot="header">
            <h2 class="font-normal">Set send information</h2>
        </div>
        <div slot="body">
            <form @submit.prevent="">
                <modal-field label="Recipient E-mail address">
                    <multiselect
                        multiple
                        v-model="emails"
                        :options="value.emails"
                        class="relative"
                    >   
                    </multiselect>
                </modal-field>

                <modal-field label="E-mail" class="mt-6">
                    <div class="relative text-sm leading-tight">
                        <input type="text" v-model="email">
                    </div>
                </modal-field>

                <modal-field label="Comment" class="mt-6">
                    <textarea v-model="comment" rows="8"></textarea>
                </modal-field>
            </form>
        </div>
        <div slot="footer" class="w__100-p">
            <div class="flex justify-end w-full w__100-p">
                <div class="flex flex__justify-end">
                    <button class="px-10 btn-primary" @click="send" :disabled="loading">Send</button>
                </div>
            </div>
        </div>
    </modal>
</template>
<script>
import axios from 'axios';

export default {
    props: {
        value: {
            type: Object,
            default: () => {},
        },
    },

    name: 'SendInformationModal',

    data() {
        return {
            email: '',
            emails: [],
            comment: '',
            loading: false,
        }
    },

    methods: {
        async send() {
            this.loading = true;

            if (!this.email && !this.emails.length) {
                this.$snotify.warning('At least one email is required!')

                this.loading = false;

                return;
            }

            if (!this.comment) {
                this.$snotify.warning('Comment required!')

                this.loading = false;

                return;
            }

            try {
                await axios.post(this.$apiUrl.exceptions.forward, {
                    id: this.value.id,
                    emails: [...this.emails, this.email].filter(item => { return item; }),
                    comment: this.comment,
                });

                this.$snotify.success('Set send information successfully');

                this.$emit('sendInformationHide');
                this.$emit('refreshTable');
            } catch(error) {
                this.$snotify.error(error.response.data.message);
            } finally {
                this.loading = false;
            }
        },
    },
}
</script>
